<template>
  <div class="mask" v-if="open">
    <div class="add">
      <!-- <div class="marsk"></div> -->
      <div class="dialog-box">
        <div class="bg">
          <border-box :src="src">
            <span slot="title" class="title border-title">添加设备</span>
            <div slot="box-container">
              <div class="dialog-body">
                <el-form
                  ref="form"
                  class="form"
                  label-width="80px"
                  label-position="right"
                  inline
                >
                  <el-form-item label="设备名">
                    <el-input v-model="form.deviceAccount" autocomplete="off" />
                  </el-form-item>
                  <el-form-item label="设备密码">
                    <el-input
                      v-model="form.devicePassword"
                      show-password
                      autocomplete="new-password"
                    />
                  </el-form-item>
                  <el-form-item label="视频账户">
                    <el-input v-model="form.videoAcount" autocomplete="off" />
                  </el-form-item>
                  <el-form-item label="视频密码">
                    <el-input
                      v-model="form.videoPassword"
                      show-password
                      autocomplete="off"
                    />
                  </el-form-item>
                  <el-form-item label="序列号">
                    <el-input v-model="form.dSerialNumber" />
                  </el-form-item>
                  <el-form-item label="额定载重">
                    <el-input v-model="form.dLimitedPaylod" />
                  </el-form-item>
                  <el-form-item label="生产日期">
                    <el-date-picker v-model="form.dBirthday" />
                  </el-form-item>
                  <el-form-item label="层站数目">
                    <el-input v-model="form.dFloors" />
                  </el-form-item>
                  <el-form-item label="X">
                    <el-input
                      v-model.number="form.dLocation.lng"
                      style="width: 110px"
                    />
                  </el-form-item>
                  <el-form-item label="Y" label-width="36px">
                    <el-input
                      v-model.number="form.dLocation.lat"
                      style="width: 110px"
                    />
                  </el-form-item>
                </el-form>
                <div class="map-box">
                  <el-form v-model="form" label-width="80px">
                    <el-form-item
                      label="设备地址"
                      prop="dAddress"
                      class="search-item"
                    >
                      <el-input v-model="form.dAddress" style="width: 340px" />
                      <div class="btn-search" @click="handleSearch">
                        <img src="../../../assets/img/search.png" alt="" />
                        <p>搜索</p>
                      </div>
                    </el-form-item>
                  </el-form>
                  <div class="map-chart"></div>
                </div>
              </div>
            </div>
          </border-box>
        </div>

        <div class="dialog-footer">
          <p class="btn" @click="close">取消</p>
          <p class="btn" @click="submit">确定</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postNewDevice } from '@/api/list'
export default {
  data() {
    return {
      src: require('../../../assets/img/add.png'),
      open: false,
      form: {
        userid:'',
        dLocation: {}
      },
      dialog: ''
    }
  },
  methods: {
    close() {
      this.open = false
      this.$refs['form'].resetFields()
      this.form = { dLocation: {} }
    },
    handleOpen() {
      this.open = true
    },
    handleSearch() {},
    submit() {
      this.form.userid = +this.$store.state.uid
      postNewDevice(this.form).then((res) => {
        this.$notify.success('新增一个设备')
        this.close()
      })
    }
  }
}
</script>
<style>
.el-picker-panel {
  color: #1ed0f8 !important;
  background-color: #021946 !important;
}
</style>
<style lang="scss" scoped>
.mask {
  width: 100%;
  height: 100%;
  display: hidden;
  backdrop-filter: blur(6px);
  position: absolute;
  left: 0;
  top: 160px;
}
.add {
  width: 60%;
  margin: auto;
}

::v-deep input:-internal-autofill-previewed,
::v-deep input:-internal-autofill-selected {
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;
  caret-color: #fff;
}
::v-deep .dv-border-box-1 {
  padding: 20px 40px;
  .border-box-content {
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 20px;
    background: linear-gradient(
      -90deg,
      rgba(7, 15, 36, 0.8) 0%,
      rgba(1, 21, 69, 0.8) 100%
    );
  }
}
.dialog-box {
  margin: auto;
  z-index: 999;
  color: #1ed0f8 !important;
  font-family: 'PingFangSC' serif;

  .dialog-header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(
      90deg,
      rgba(1, 21, 69, 0.8) 0%,
      rgba(7, 15, 36, 0) 100%
    );
    padding-left: 15px;
    span {
      color: #ffffff;
      font-weight: 500;
      font-size: 18px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(30, 208, 248, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      vertical-align: middle;
    }
    img {
      width: 28px;
      height: 28px;
      vertical-align: middle;
      margin-right: 15px;
    }
  }

  .dialog-body {
    padding: 30px;
    display: flex;
    ::v-deep .el-input__inner {
      background-color: #021946;
      color: #1ed0f8;
    }
    .form {
      width: 460px;
      display: inline-block;
      .el-input {
        width: 266px;
      }
    }
    .map-box {
      // float: right;
      height: 580px;
      border: 1px solid #003aa3;
      padding: 30px;
    }
    .btn-search {
      background-image: url(../../../assets/img/btn-bg.png);
      background-size: 100% 100%;
      width: 98px;
      height: 45px;
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      margin-left: 10px;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .map-chart {
      width: 533px;
      height: 375px;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .btn {
      margin-right: 27px;
      background-size: 155px 38px;
      background-repeat: no-repeat;
      width: 155px;
      line-height: 38px;
      color: #ffffff;
      font-size: 18px;
      color: #0d9bd8;
      text-align: center;
      cursor: pointer;
      background-image: url('../../../assets/img/false-select.png');
      &:hover {
        background-image: url('../../../assets/img/true-select.png');
      }
    }
  }
}
.marsk {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: inline-block;
}
</style>
