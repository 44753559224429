<template>
  <div class="general">
    <div class="general-bar">
      <p class="total">共 {{ list.length }} 台设备</p>
      <div class="btn" @click="handleAdd">
        <img
          src="@/assets/img/add.png"
          alt=""
          width="24px"
          height="24px"
        />
        <p class="btn-text">新增设备</p>
      </div>
    </div>
    <main class="main">
      <div class="grid">
        <div class="bg" v-for="(item, i) in list" :key="i" @click="handleView(item)">
          <div class="top">
            <img src="@/assets/img/to-detail.png" width="100%" height="100%" />
            <p class="num">{{ item.id }}</p>
            <!-- <img
              src="@/assets/img/to-detail.png"
              alt="详情"
              width="100%"
              height="100%"
            /> -->
          </div>
          <pieChart
            :chartId="'pieChart' + i"
            :chartData="item.data.assessment"
            :chartOption="chartOption"
            stype="width:100%;height:180px;"
          />
        </div>
      </div>
    </main>
    
    <Lfooter></Lfooter>
    <add ref="add" class="" />
  </div>
</template>
<script>
import Lfooter from '@/views/layout/footer.vue'
import pieChart from '@/views/components/pieChart.vue'
import add from './add'
import {getDeviceData} from '@/api/list'

export default {
  components: { pieChart, add ,Lfooter},
  data() {
    return {
      chartId: 'chartId',
      chartOption: {
        height: '184px',
        color: ['#FFFB29', '#F06E24', '#207AE8', '#3ECCAE'],
        legend:{itemGap:15},
        series: {
          center: ['50%', '40%']
        }
      },
      dataSource: [
        {
          name: '语文',
          value: 45
        },
        {
          name: '数学',
          value: 86
        },
        {
          name: '英语',
          value: 77
        },
        {
          name: 'sddf',
          value: 60
        }
      ],
      list: []
    }
  },
  computed:{
    uid(){return this.$store.state.uid}
  },
  created(){
    this.getDevices()
  },
  methods: {
    getDevices(){
      getDeviceData({id:this.uid}).then(res => {
        this.list = res
      })
    },
    handleAdd() {
      this.$refs['add'].handleOpen()
    },
    handleView({ id }) {
      console.log(id);
      this.$store.commit('SET_EQUIPMENT', id)
      this.$router.push({
        name: 'Monitor',
        query: { equipment: id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.general {
  width: 100%;
  height: 100%;
  padding: 0 45px;
  color: #1ed0f8 !important;
  font-family: 'PingFangSC' serif;
}
.general-bar {
  width: 100%;
  padding-top: 9px;
  padding-bottom: 17px;
  display: flex;
  justify-content: space-between;

  .total {
    font-size: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  .btn {
    background-image: url('../../../assets/img/btn-bg.png');
    background-size: 100% 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .btn-text {
    font-size: 20px;
    letter-spacing: 1px;
    margin-left: 18px;
    line-height: 24px;
  }
}
.main {
  .grid::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
  .grid {
    height: 844px;
    display: grid;
    grid-template-columns: repeat(5, 20%);
    grid-template-rows: 288px;
    /* grid-row-gap: 20px;
    grid-column-gap: 26px; */
    overflow: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    .top {
      display: flex;
      align-items: center;
      height: 22px;
      line-height: 22px;
      cursor: pointer;
      p {
        flex: 1;
        height: 22px;
        line-height: 22px;
        color: #ffffff;
        font-weight: 600;
        font-size: 18px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(30, 208, 248, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  .bg {
    background-image: url('../../../assets/img/equip-bg.png');
    background-size: 100% 100%;
    /* width: 283px; */
    /* height: 268px; */
    margin-right: 26px;
    margin-bottom: 20px;
    /* float: left; */
    padding: 30px;
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
  /* .add {
    width: 1200px;
  } */
}
</style>
