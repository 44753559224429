import request from '@/utils/request.js'

//获取所有设备

export function getDeviceData(params) {
  return request({
    url: '/api/v1/devices',
    method: 'get',
    params
  })
}
//注册设备

export function postNewDevice(data) {
  return request({
    url: '/api/v1/devices',
    method: 'post',
    data
  })
}
