<template>
  <div>
    <div
      class="pie-chart"
      :id="chartId"
      :style="{ height: chartOption.height || '100%', width: '100%' }"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  props: {
    chartData: {
      style: Array,
      default: () => {
        return []
      }
    },
    chartOption: {
      stype: Object,
      default: () => {
        return {}
      }
    },
    chartId: {
      style: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      chart: null,
      unit: ''
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartData: function () {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      let legends = [],
        total = 0
      this.chartData.forEach((item) => {
        legends.push(item.name)
        total += item.value / 1
      })

      const options = {
        color: this.chartOption.color,
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0, .4)',
          textStyle: {
            color: '#333'
          },
          formatter: function (params) {
            const str = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:${
              params.color
            }"></span><span style="color:${params.color}">${
              params.data.name
            }：${params.data.value}（${(
              (params.data.value * 100) /
              total
            ).toFixed(2)}%）</span><br />`
            return str
          }
        },
        legend: {
          // 图例
          icon: 'roundRect',
          itemWidth: this.chartOption.legend?.itemWidth|| 15,
          itemHeight: this.chartOption.legend?.itemHeight|| 9,
          itemGap: this.chartOption.legend?.itemGap|| 12, // 图例距离，横纵向都是
          padding: [40, 0, 0, 0],
          bottom: 0,
          data: legends,
          formatter: function (name) {
            var arr = ['{name|' + name + '}']
            return arr.join('\n')
          },
          textStyle: {
            rich: {
              name: {
                fontSize: this.chartOption.legend?.textStyle?.rich?.name?.fontSize || 12,
                align: 'left',
                color: '#1ED0F8'
                // width: 60 // 设置宽度可规整图例但是不适合超长数据
              }
            }
          }
        },
        series: [
          {
            type: 'pie',
            avoidLabelOverlap: true,
            label: {
              normal: {
                show: true,
                position: 'center',
                formatter: function () {
                  return `{count|${total}}`
                },
                rich: {
                  count: {
                    fontSize: 28,
                    fontWeight: '500',
                    fontFamily: 'Roboto',
                    color: '#1ED0F8',
                    textShadowColor: 'rgba(133, 233, 255, 0.3)',
                    textShadowBlur: 8,
                    textShadowOffsetX: 0,
                    textShadowOffsetY: 0
                  }
                }
              }
            },
            itemStyle: {
              borderColor: '#020925',
              borderWidth: 2
            },
            radius: this.chartOption.series?.radius || ['50%', '70%'],
            center: this.chartOption.series?.center || ['50%', '50%'],
            data: this.chartData
          }
        ]
      }
      this.chart.setOption(options, true)
    },
    initChart() {
      const target = document.getElementById(this.chartId)
      this.chart = echarts.init(target)
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>
